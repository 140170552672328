<template>
    <div class="mt-12">
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
        <div class="md:flex">
            <div><label class="text-base font-semibold text-gray-900">Attestations de présence</label>
                <p class="text-sm text-gray-500">Saisir les absences pour chaque jour de formation</p>
            </div>
            <div class="ml-auto">
                <button class="btn-primary" v-if="training.attendance_sheet_done && statusOk && timeToEditAttendance"
                    @click="sendAttendanceMails">
                    Envoyer les attestations</button>
                <button class="ml-2 btn-primary" @click.prevent="generateAttendanceSheet"
                    v-if="(training.training_status_id === 3 || training.training_status_id === 4) && (new Date() <= new Date(training.end_date))">
                    Exporter la
                    feuille d'émargement</button>
            </div>
        </div>
        <AlertMessage v-if="!isEditable" class="my-5" type="warning" :title="titleMessage" :content="alertMessage" />
        <div v-if="statusOk && timeToEditAttendance && !isLoading">
            <AlertMessage class="my-5" type="success" :title="'Absence saisies'"
                v-if="profile?.role?.slug === 'gestionnaire' && training.attendance_sheet_done"
                content="La saisie des absences n'est plus éditable, elle a été notée comme terminée pour cette formation" />
            <AlertMessage class="my-5" type="success" :title="'Absence saisies'"
                v-if="profile?.role?.slug === 'administrateur' && training.attendance_sheet_done"
                content="La saisie des absences n'est plus éditable, elle a été notée comme terminée pour cette formation"
                :actions="[{ text: 'Annuler', onClick: cancelAttendanceDone }]" />

            <button class=" btn-success md:ml-auto my-5" @click="confirmAttendanceDone"
                v-if="!training.attendance_sheet_done">
                Marquer comme terminé</button>
            <div class="mt-5">
                <span class="isolate inline-flex rounded-md shadow-sm">
                    <span v-for="( date, index ) in trainingDaysAttendance " :key="date">
                        <a :href="'#day' + index" :alt="formatDate(date)">
                            <button type="button"
                                :class="{ 'btn-group-start': index === 0, 'btn-group-middle': index < (Object.keys(trainingDaysAttendance).length - 1), 'btn-group-end': index === (Object.keys(trainingDaysAttendance).length - 1) }">
                                {{ formatDateTab(date) }}
                            </button>
                        </a>
                    </span>
                </span>
            </div>
            <div v-for="( date, index ) in trainingDaysAttendance" :key="date" class="mt-0 pt-28" :id="'day' + index">
                <div class="relative mb-4">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                    </div>
                    <div class="relative flex justify-center">
                        <span class="bg-white px-2 text-sm text-gray-500">{{ formatDate(date) }}</span>
                    </div>

                </div>
                <div class="mt-4">
                    <table class="min-w-full divide-y divide-gray-300" v-if="participants">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                    Stagiaire
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Matin
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Après-midi
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <tr v-for=" participant in participants" :key="participant.id" class="even:bg-gray-50">
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {{
                                    participant.user?.name
                                    }}
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <SwitchGroup as="div" class="flex items-center"
                                        :class="editing === false ? 'pointer-events-none' : ''">
                                        <Switch :disabled="editing === false"
                                            v-model="attendance[date.toISOString().split('T')[0]].morning[participant.id]"
                                            @update:model-value="checkAttendance(participant.id, date.toISOString().split('T')[0], 'morning')"
                                            :class="[attendance[date.toISOString().split('T')[0]].morning[participant.id] ? 'bg-rose-500' : 'bg-emerald-400', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                            <span aria-hidden="true"
                                                :class="[attendance[date.toISOString().split('T')[0]].morning[participant.id] ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                        </Switch>
                                        <SwitchLabel as="span" class="ml-3 text-sm">
                                            <span class="font-medium text-gray-900">{{
                                                attendance[date.toISOString().split('T')[0]].morning[participant.id]
                                                ?
                                                'absent' : 'présent'
                                                }}</span>
                                        </SwitchLabel>
                                    </SwitchGroup>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <SwitchGroup as="div" class="flex items-center"
                                        :class="editing === false ? 'pointer-events-none' : ''">
                                        <Switch :disabled="editing === false"
                                            v-model="attendance[date.toISOString().split('T')[0]].afternoon[participant.id]"
                                            @update:model-value="checkAttendance(participant.id, date.toISOString().split('T')[0], 'afternoon')"
                                            :class="[attendance[date.toISOString().split('T')[0]].afternoon[participant.id] ? 'bg-rose-500' : 'bg-emerald-400', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                            <span aria-hidden="true"
                                                :class="[attendance[date.toISOString().split('T')[0]].afternoon[participant.id] ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                        </Switch>
                                        <SwitchLabel as="span" class="ml-3 text-sm">
                                            <span class="font-medium text-gray-900">{{
                                                attendance[date.toISOString().split('T')[0]].afternoon[participant.id]
                                                    ?
                                                    'absent' : 'présent'
                                                }}</span>
                                        </SwitchLabel>
                                    </SwitchGroup>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <button class="btn-success md:ml-auto my-5" @click="confirmAttendanceDone"
                v-if="!training.attendance_sheet_done">
                Marquer comme terminé</button>
        </div>

        <BigLoader v-if="isLoading" title="Génération des feuilles en cours" text="" />
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import BigLoader from '@/components/app/BigLoader.vue';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const route = useRoute();

const props = defineProps({
    training: [Array, Object],
});
const emit = defineEmits(['close', 'confirm']);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const trainingDaysAttendance = ref([]);
let editing = ref(false);
const timeToEditAttendance = ref(false);
const absences = ref([]);
const isLoading = ref(false);
const titleMessage = ref('');
const alertMessage = ref('');
const statusOk = ref(false);
const profile = ref([]);
const loaderTitle = ref('');

// Sample JSON data for participants
const participants = ref([]);

// Function to format the date
const formatDate = (date) => date.toLocaleDateString('fr-FR', {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
});
const formatDateTab = (date) => date.toLocaleDateString('fr-FR', {
    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
});

// Computed property to generate the list of training days
const trainingDays = computed(() => {
    const days = [];
    const currentDate = new Date(training.value.startDate);

    while (currentDate <= training.value.endDate) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
});

// Reactive property to store attendance status for each participant on each day
const attendance = ref({});

// Initialize attendance with all "Présent" selected by default for both morning and afternoon
watch(trainingDaysAttendance, (newDays) => {
    newDays.forEach((date) => {
        const dateString = date.toISOString().split('T')[0];
        attendance.value[dateString] = { morning: {}, afternoon: {} };
        participants.value.forEach((participant) => {
            attendance.value[dateString].morning[participant.id] = false;
            attendance.value[dateString].afternoon[participant.id] = false;
        });
    });
}, { immediate: true });

// Function to fetch absences for the training
const fetchAbsences = async () => {
    isLoading.value = true;
    try {
        const response = await client.get(`api/absences/by-training/${route.params.id}`);
        absences.value = response.data;
        console.log(absences.value);
        absences.value.forEach((absence) => {
            if (!attendance.value[absence.absence_date]) {
                attendance.value[absence.absence_date] = { morning: {}, afternoon: {} };
            }
            attendance.value[absence.absence_date][absence.half_day][absence.profile_id] = true;
        });
        isLoading.value = false;
    } catch (error) {
        console.error(error);
    }
};
const checkAttendance = (profileId, absenceDate, halfDay) => {
    if (absences.value.length > 0) {
        const absence = absences.value.find((absence) => absence.profile_id === profileId && absence.absence_date === absenceDate && absence.half_day === halfDay);
        if (absence) {
            deleteAttendance(absence.id);
        } else {
            saveAttendance(profileId, absenceDate, halfDay);
        }
    } else {
        saveAttendance(profileId, absenceDate, halfDay);
    }
};
const saveAttendance = async (profileId, absenceDate, halfDay) => {
    const formattedDate = new Date(absenceDate).toISOString().split('T')[0] + ' ' + new Date().toLocaleTimeString();
    try {
        const response = await client.post(`api/absences`, {
            profile_id: profileId,
            training_id: Number(route.params.id),
            absence_date: formattedDate,
            half_day: halfDay,
        })
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        notificationMessage.value += '';
        emit('confirm');
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
        setTimeout(() => { emit('confirm'); }, 5000);
    }
};
const deleteAttendance = async (attendanceId) => {
    try {
        const response = await client.delete(`api/absences/${attendanceId}`);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emit('confirm');
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const confirmAttendanceDone = async () => {
    try {
        const response = await client.patch(`api/trainings/${route.params.id}`, {
            attendance_sheet_done: true,
            training_status_id: 5,
        })
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emit('confirm');
        editing.value = false;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const sendAttendanceMails = async () => {
    try {
        const response = await client.get(`api/send-certificates-email/${route.params.id}`);
        showNotification.value = true;

        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, les emails ont bien été envoyés';
        notificationMessage.value = response.data.message;
        if (response.data.failed_emails.length > 0) {
            response.data.failed_emails.forEach((email) => {
                notificationMessage.value += '\r\n' + email + '\r\n';
            });

        } else {
            setTimeout(() => { showNotification.value = false; }, 3000);
        }
    } catch (error) {
        console.log(error);
        showNotification.value = false;
        notificationType.value = 'error';
        notificationTitle.value = 'une erreur s\'est produite';
        notificationMessage.value = '';
    }
}

const cancelAttendanceDone = async (statusId) => {
    editing.value = true;
    try {
        const response = await client.patch(`api/trainings/${route.params.id}`, {
            attendance_sheet_done: false,
            training_status_id: 3,
        })
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emit('confirm');
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};
// Computed property to check training status and populate a message for each condition
const checkTime = () => {
    const today = new Date().toISOString().split('T')[0];
    const endDate = new Date(props.training.end_date).toISOString().split('T')[0];

    if (endDate <= today) {
        timeToEditAttendance.value = true;
    } else {
        alertMessage.value += ' La formation n\'est pas terminée. ';
    }
};

const checkStatus = () => {
    if (props.training.training_status_id === 3 || props.training.training_status_id === 4 || props.training.training_status_id === 5) {
        statusOk.value = true;
    } else {
        alertMessage.value += " Le statut de la formation ne permet pas de saisir les absences. ";
    }
};

const isEditable = computed(() => {
    if ((timeToEditAttendance.value === true) && (statusOk.value === true)) {
        return true;
    } else {
        titleMessage.value = 'L\'attestation de présence n\'est pas éditable';
        return false;
    }
});

const generateAttendanceSheet = async () => {
    const startDate = moment(new Date()).format('YYYY-MM-DD');
    const title = props.training.title.substring(0, 20).toLowerCase().replace(/\s/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    isLoading.value = true;
    loaderTitle.value = 'Génération de l\'émargement en cours';
    try {
        const response = await client.get(`api/generate-attendance-sheet/${props.training.id}`, {
            responseType: 'blob',
        }).then((response) => {
            isLoading.value = false;
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${title}-emargement-${startDate}.pdf`);
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    } catch (error) {
        isLoading.value = false;

        if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
                const errorData = JSON.parse(reader.result);
                notificationMessage.value = errorData.error || "Une erreur s'est produite lors de la génération du document.";
            };
            reader.readAsText(error.response.data);
        } else {
            notificationMessage.value = error.message || "Une erreur s'est produite lors de la génération du document.";
        }

        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur';
    }
}

onMounted(() => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    checkTime();
    checkStatus();
    participants.value = props.training.trainees;
    const days = [];
    const currentDate = new Date(props.training.start_date);

    while (currentDate <= new Date(props.training.end_date)) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    trainingDaysAttendance.value = days;
    if (timeToEditAttendance.value && statusOk.value) {
        fetchAbsences();
    }
    if (props.training.attendance_sheet_done) {
        editing.value = false;
    } else {
        editing.value = true;
    }
});
</script>
